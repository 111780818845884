#qualification{
 display: grid;
 margin-top: 8rem;
 color: var(--color-bg);
}

.resume_container{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1.8rem;
}

.timeline {
  background: var(--color-primary);
  padding: 1.8rem;
  border-radius: 2rem;
  position: relative;
  border: 1px solid transparent;
  transition: var(--transition);
}

.timeline:hover{
  background: transparent;
  color: var(--color-white);
  border-color: var(--color-primary-variant);
  cursor: default;
}

.timeline_oneTitle{
  width: 100%;
  align-items: center;
}

.timeline_item{
  position: relative;
  padding-left: 3.1rem;
  padding-bottom: 2.1rem;
}

.timeline_item:last-child{
  padding-bottom: 0;
}

.timeline_item::before{
  content: '';
  width: 2px;
  height: 100%;
  background: var(--color-bg);
  position: absolute;
  left: .25rem;
  top: 0;
}

.timeline .icon-graduation,
.timeline .icon-briefcase{
  position: absolute;
  left: -0.4rem;
  top: 0;
  font-size: 2rem;
  color: var(--color-bg);
  background: var(--color-primary);
  padding: 0.4rem 0;
}

.timeline_date{
  font-size: .9rem;
}

/* ============= MEDIA QUERIES (MEDIUM DEVICES) ============= */
@media screen and (max-width: 1024px){
  .resume_container{
    justify-items: center;
    grid-template-columns: 1fr;
    gap: 1rem;
  }
  .timeline{
    width: 80%;
  }

}

/* ============= MEDIA QUERIES (SMALL DEVICES) ============= */
@media screen and (max-width: 600px){
  .resume_container{
    grid-template-columns: 1fr, 1fr;
    gap: 1rem;
  }
  .timeline{
    width: 100%;
  }

}
