.home_container{
  margin-top: 5rem;
  display: grid;
  row-gap: 7rem;
}

.home_content{
  display: grid;
  grid-template-columns: 116px repeat(2, 1fr);
  padding-top: 5.5rem;
  column-gap: 2rem;
  align-items: center;
}

.home_social{
  display: grid;
  grid-template-columns: max-content;
  row-gap: 1rem;
}

.home_social a{
  font-size: 1.5rem;

}

.home_title{
  font-size: 4rem;
  margin-bottom: .5rem;
}

.home_subtitle{
  position: relative;
  color: var(--color-primary);
  padding-left: 4.5rem;
  margin-bottom: 1rem;
}

.home_subtitle::before{
  content: '';
  position: absolute;
  width: 65px;
  height: 1px;
  background: var(--color-white);
  left: 0;
  top: 1rem;
}

.home_description{
  max-width: 400px;
  margin-bottom: 3rem;
}

.home_img{
  background: url(../../assets/me-about.webp);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  box-shadow: inset 0 0 0 9px var(--color-primary-variant);
  order: 1;
  justify-self: center;
  width: 500px;
  height: 500px;
  animation: profile_animate 8s ease-in-out infinite 1s;
}

@keyframes profile_animate{
  0% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  }
  50% {
    border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
  }
  100% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  }
}

.home_scroll{
  display: grid;
}

.home_scroll-btn .scr_icon{
  margin-top: .2rem;
  margin-left: .3rem;
  font-size: 2rem;
}
.home_scroll-btn{
  display: flex;
  align-items: center;
}

/* ============= MEDIA QUERIES (MEDIUM DEVICES) 1 ============= */
@media screen and (max-width: 1024px){
  .home_content{
    grid-template-columns: 100px repeat(2, 1fr);
    column-gap: 1rem;
  }

  .home_img{
    width: 300px;
    height: 300px;
  }

  .home_title{
    font-size: 1.5rem;
    margin-bottom: .5rem;
  }

  .home_subtitle{
    position: relative;
    font-size: .9rem;
    color: var(--color-primary);
    padding-left: 0;
    margin-bottom: 1rem;
  }
  
  .home_subtitle::before, .home_scroll{
    display: none;
  }

}

/* ============= MEDIA QUERIES (MEDIUM DEVICES) 2 ============= */
@media screen and (max-width: 1180px){
  .home_content{
    grid-template-columns: 100px repeat(2, 1fr);
    column-gap: 1rem;
  }

  .home_img{
    width: 300px;
    height: 300px;
  }

  .home_title{
    font-size: 1.5rem;
    margin-bottom: .5rem;
  }

  .home_subtitle{
    position: relative;
    font-size: .9rem;
    color: var(--color-primary);
    padding-left: 0;
    margin-bottom: 1rem;
  }
  
  .home_subtitle::before{
    display: none;
  }

  .home_container{
    row-gap: 3rem;
  }

}

/* ============= MEDIA QUERIES (SMALL DEVICES) ============= */
@media screen and (max-width: 600px){
  .home_content{
    grid-template-columns: .5fr 3fr;
    padding-top: 3.5rem;
  }

  .home_img{
    order: initial;
    justify-self: initial;
    margin-bottom: 1rem;
  }

  .home_data{
    grid-column: 1/3;
  }
}